import React from "react";
import { NavLink } from "react-router-dom";
import BannerCard from "./BannerCard";
import "../styles/BannerList.scss";

export default function BannerList({ banners, refreshBanners, userData }) {
  return (
    <div className="container-fluid">
      <h2>Aktuelle Banner:</h2>
      <div>
        <a href="/current-link" target="_blank">
          <img src="/current-banner" alt="Banner" width="550" />
        </a>

        <br />

        <a href="/current-link-small" target="_blank">
          <img
            src="/current-banner-small"
            alt="kleiner Banner"
            width="325"
          />
        </a>
      </div>

      <NavLink to="/create">
        <div className="mr-2 my-4">
          <i className="fa fa-plus"></i> Banner erstellen
        </div>
      </NavLink>

      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Live</th>
            <th scope="col">Banner</th>
            <th scope="col">kleiner Banner</th>
            <th scope="col"></th>
          </tr>
        </thead>
        {banners &&
          banners.map((banner) => (
            <BannerCard
              key={banner._id}
              banner={banner}
              refreshBanners={refreshBanners}
              userData={userData}
            />
          ))}
      </table>
    </div>
  );
}
