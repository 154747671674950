import React, { useState } from "react";
import { useHistory, NavLink } from "react-router-dom";
import Dropzone from "react-dropzone";
import DateTimePicker from "react-datetime-picker";
import "../styles/FormStyles.scss";

export default function BannerCreate({ refreshBanners, userData }) {
  const [createdAt, setCreatedAt] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [liveAt, setLiveAt] = useState(new Date());
  const [bannerImage, setBannerImage] = useState("");
  const [bannerLink, setBannerLink] = useState("https://dfi-vertrieb.de");
  const [smallBannerImage, setSmallBannerImage] = useState("");
  const [smallBannerLink, setSmallBannerLink] = useState(
    "https://dfi-vertrieb.de"
  );

  const onChange = (files) => {
    const file = files[0];

    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      const result = event.target.result;

      console.log(`Done ${result.length}`);
      setBannerImage(result);
    });
    reader.readAsDataURL(file);
  };
  const onChangeSmall = (files) => {
    const file = files[0];

    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      const result = event.target.result;

      console.log(`Done ${result.length}`);
      setSmallBannerImage(result);
    });
    reader.readAsDataURL(file);
  };

  const resetForm = () => {
    setCreatedAt("");
    setLiveAt("");
    setBannerImage("");
    setBannerLink("");
    setSmallBannerImage("");
    setSmallBannerLink("");
  };
  let history = useHistory();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      createdAt,
      liveAt,
      bannerImage,
      bannerLink,
      smallBannerImage,
      smallBannerLink,
    };
    try {
      await fetch(".netlify/functions/createBanner", {
        method: "POST",
        body: JSON.stringify(body),
      });
      resetForm();
      refreshBanners();
      history.push("/");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container formContainer">
      <div className="row ">
        <div className="col-12 buttonCol">
          <NavLink className="backLink" to="/list">
            <div className="mr-2 backButton">
              <i className="fa fa-caret-left"></i> Zurück
            </div>
          </NavLink>
        </div>
        <div className="col-12 formCol">
          <div className="card">
            <div className="card-header">Banner erstellen</div>
            <div className="card-body">
              <form action="" onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="hidden"
                    name="createdAt"
                    className="form-control"
                    value={createdAt}
                    onChange={(e) => setCreatedAt(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label className="inputLabel" htmlFor="liveAt">
                    Live-Schaltung
                  </label>
                  <br />
                  <DateTimePicker
                    className="dateTime"
                    name="liveAt"
                    value={liveAt}
                    onChange={setLiveAt}
                    required={true}
                  />
                </div>

                <hr />

                {bannerImage ? (
                  <img
                    className="preview img-fluid"
                    alt="preview"
                    src={bannerImage}
                  />
                ) : null}
                <Dropzone
                  multiple={false}
                  onDrop={onChange}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className="dropzone">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} onChange={onChange} />
                        <p>
                          Bild-Datei hier her ziehen oder per Klick hochladen
                        </p>
                      </div>
                    </section>
                  )}
                </Dropzone>
                <div className="form-group">
                  <label className="inputLabel" htmlFor="bannerLink">
                    Banner-Link
                  </label>
                  <input
                    type="text"
                    name="bannerLink"
                    className="form-control"
                    value={bannerLink}
                    onChange={(e) => setBannerLink(e.target.value)}
                  />
                </div>

                <hr />

                {smallBannerImage ? (
                  <img
                    className="preview img-fluid"
                    alt="preview"
                    src={smallBannerImage}
                  />
                ) : null}
                <Dropzone multiple={false} onDrop={onChangeSmall}>
                  {({ getRootProps, getInputProps }) => (
                    <section className="dropzone">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} onChange={onChangeSmall} />
                        <p>
                          Bild-Datei hier her ziehen oder per Klick hochladen
                        </p>
                      </div>
                    </section>
                  )}
                </Dropzone>
                <div className="form-group">
                  <label className="inputLabel" htmlFor="smallBannerLink">
                    kleiner Banner-Link
                  </label>
                  <input
                    type="text"
                    name="smallBannerLink"
                    className="form-control"
                    value={smallBannerLink}
                    onChange={(e) => setSmallBannerLink(e.target.value)}
                  />
                </div>
                <button type="submit" className="btn btn-primary">
                  Speichern
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
