import React from 'react'
import logo from '../img/dfiv_logo_rgb_small.png'
import'../styles/Welcome.scss'


export default function Welcome() {
    return (
        <div className="welcomeDiv">
            <img className="img-fluid" src={logo} alt="logo dfiv"/>
           <h1>Please Login</h1> 
        </div>
    )
}
