import React, { useEffect, useState, useCallback } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import netlifyIdentity from "netlify-identity-widget";
import BannerList from "./components/BannerList";
import Welcome from "./components/Welcome";
import BannerCreate from "./components/BannerCreate";
import BannerUpdate from "./components/BannerUpdate";
import "./styles/App.scss";


function App() {
  const [banners, setBanners] = useState([]);
  const [user, setUser] = useState();

  console.log("this are the banners", banners)

  const loadBanners = useCallback(async () => {
    try {
      const res = await fetch("/.netlify/functions/getBanners");
      const banners = await res.json();

      const sortedBanners = banners.sort((a, b) => {
        return new Date(a.liveAt) - new Date(b.liveAt);
      });
      setBanners(sortedBanners);

    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    loadBanners();
    netlifyIdentity.init({});
  }, [loadBanners]);

  netlifyIdentity.on("login", (user) => {
    netlifyIdentity.close();
    setUser(user);
  });

  netlifyIdentity.on("logout", () => setUser());

  return (
    <BrowserRouter>
      {!user && (
        <div className="container headerContainer">
          <div className="row colWrapper">
            <div className="col-12">
              <h2 className="textCol">DFIV Banner Tool</h2>
            </div>
            <div className="col-12 buttonCol">
              <button
                className="btn btn-primary loginButton"
                onClick={() => {
                  netlifyIdentity.open();
                }}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      )}
      <Switch>
        {user ? (
          <>
            <Route
              path="/list"
              component={() => (
                <BannerList
                  banners={banners}
                  refreshBanners={loadBanners}
                  userData={user}
                />
              )}
            />
            <Route
              path="/create"
              component={() => (
                <BannerCreate refreshBanners={loadBanners} userData={user} />
              )}
            />
            <Route
              path="/update/:id"
              component={() => (
                <BannerUpdate
                  banners={banners}
                  refreshBanners={loadBanners}
                  userData={user}
                />
              )}
            />
            <Route component={() => <Redirect to="/list" />} />
          </>
        ) : (
          <>
            <Route path="/" exact component={Welcome} />
          </>
        )}
      </Switch>
    </BrowserRouter>
  );
}

export default App;
