import React from "react";
import { NavLink } from "react-router-dom";
import "../styles/BannerCard.scss";

export default function BannerCard({ banner, refreshBanners, userData }) {
  const deleteBanner = async () => {
    const id = banner._id;
    try {
      await fetch("/.netlify/functions/deleteBanner", {
        method: "DELETE",
        body: JSON.stringify({ id }),
      });
      refreshBanners();
    } catch (error) {
      console.error(error);
    }
  };
  

  var date = new Date(banner.liveAt).toString();
  var shortDate = date.slice(0, 25);

  return (
    <tbody>
      <tr>
        <td>{shortDate}</td>
        <td>
          <img src={banner.bannerImage} className=" imgBig" alt="banner" />
          <br />
          <p>{banner.bannerLink}</p>
        </td>
        <td>
          {banner.smallBannerImage && (
            <div>
              <img
                src={banner.smallBannerImage}
                className=" imgSmall"
                alt=" small banner"
              />
              <br />
              <p>{banner.smallBannerLink}</p>
            </div>
          )}
        </td>
        <td>
          <NavLink to={`/update/${banner._id}`}>
            <button className="btn btn-warning mr-2 update">Update</button>
          </NavLink>
          <button className="btn btn-danger mr-2 delete" onClick={() => {if(window.confirm('Are you sure you wish to delete this item?')) deleteBanner()}}>
            Löschen
          </button>
        </td>
      </tr>
    </tbody>
  );
}
